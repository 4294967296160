// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-approach-tsx": () => import("./../../../src/pages/approach.tsx" /* webpackChunkName: "component---src-pages-approach-tsx" */),
  "component---src-pages-campaigns-tsx": () => import("./../../../src/pages/campaigns.tsx" /* webpackChunkName: "component---src-pages-campaigns-tsx" */),
  "component---src-pages-coupons-tsx": () => import("./../../../src/pages/coupons.tsx" /* webpackChunkName: "component---src-pages-coupons-tsx" */),
  "component---src-pages-course-booking-tsx": () => import("./../../../src/pages/course-booking.tsx" /* webpackChunkName: "component---src-pages-course-booking-tsx" */),
  "component---src-pages-course-schedule-tsx": () => import("./../../../src/pages/course-schedule.tsx" /* webpackChunkName: "component---src-pages-course-schedule-tsx" */),
  "component---src-pages-impressum-2-tsx": () => import("./../../../src/pages/impressum-2.tsx" /* webpackChunkName: "component---src-pages-impressum-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-netiquette-tsx": () => import("./../../../src/pages/netiquette.tsx" /* webpackChunkName: "component---src-pages-netiquette-tsx" */),
  "component---src-pages-our-room-tsx": () => import("./../../../src/pages/our-room.tsx" /* webpackChunkName: "component---src-pages-our-room-tsx" */),
  "component---src-pages-personal-training-tsx": () => import("./../../../src/pages/personal-training.tsx" /* webpackChunkName: "component---src-pages-personal-training-tsx" */),
  "component---src-pages-price-cards-tsx": () => import("./../../../src/pages/price-cards.tsx" /* webpackChunkName: "component---src-pages-price-cards-tsx" */),
  "component---src-pages-price-tsx": () => import("./../../../src/pages/price.tsx" /* webpackChunkName: "component---src-pages-price-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-specials-tsx": () => import("./../../../src/pages/specials.tsx" /* webpackChunkName: "component---src-pages-specials-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */),
  "component---src-templates-campaign-post-tsx": () => import("./../../../src/templates/campaign-post.tsx" /* webpackChunkName: "component---src-templates-campaign-post-tsx" */)
}

